import React from 'react';

import './App.css';
import CareMap from './pages/CareMap';

function App() {
  return <CareMap />;
}

export default App;
