import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { SVGMap } from 'react-svg-map';
import usaCounties from '@svg-maps/usa.counties';
import Papa from 'papaparse';

// Modularized imports for now. later, use babel-plugin-import
import Button from 'antd/es/button';
import Spin from 'antd/es/spin';
import Descriptions from 'antd/es/descriptions';

import { useDebounce } from '../lib/hooks';
import caremapdata from './caremapdata.csv';
import './CareMap.css';

const statusMap = {
  '000': 'No Coverage', // other map legend: 7
  '100': 'Amedisys Only', // other map legend: 6
  '010': 'ClearCare Only', // other map legend: 5
  '001': 'LHC Only', // other map legend: 4
  '110': 'ClearCare + Amedisys', // other map legend: 3
  '011': 'ClearCare + LHC', // other map legend: 2
  '101': 'No ClearCare Coverage', // other map legend: 7
  '111': 'ClearCare + Amedisys + LHC', // other map legend: 1
};

const search = window.location.search;
const params = new URLSearchParams(search);

const MapContainer = ({ parsedData, filterValue, tooltipEnabled }) => {
  // console.log('MapContainer rendered');
  const [mouseMouseMoveEvent, setMouseMoveEvent] = useState(null);
  const [hoveredCounty, setHoveredCounty] = useState(null);
  const mapContainerRef = useRef(null);

  const throttledMouseMoveEvent = useDebounce(mouseMouseMoveEvent, 50);

  useEffect(() => {
    if (throttledMouseMoveEvent) {
      const countyId = throttledMouseMoveEvent.target.id;
      const countyData = (parsedData && parsedData[countyId]) || null;
      if (countyData) {
        if (!hoveredCounty || hoveredCounty.id !== countyId) {
          setHoveredCounty({
            ...countyData,
            name: throttledMouseMoveEvent.target.getAttribute('aria-label'),
            id: countyId,
            coverageStr: statusMap[countyData.statusKey] || null,
            position: {
              x: throttledMouseMoveEvent.pageX,
              y: throttledMouseMoveEvent.pageY,
              containerTop: mapContainerRef.current.offsetTop,
              containerLeft: mapContainerRef.current.offsetLeft,
            },
          });
        }
      } else {
        setHoveredCounty(null);
      }
    }
  }, [throttledMouseMoveEvent, hoveredCounty, parsedData]);

  const handleLocationMouseMove = event => {
    event.persist();
    setMouseMoveEvent(event);
  };

  return (
    <div
      ref={mapContainerRef}
      className="map-container"
      onMouseMove={tooltipEnabled ? handleLocationMouseMove : null}
    >
      <CareMap parsedData={parsedData} filterValue={filterValue} />
      {tooltipEnabled && <MapTooltip hoveredCounty={hoveredCounty} />}
    </div>
  );
};

const MapTooltip = React.memo(function MapTooltip({ hoveredCounty }) {
  // console.log('MapTooltip rendered', hoveredCounty);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const tooltipRef = useRef(null);
  const countyPositionX =
    (hoveredCounty && hoveredCounty.position && hoveredCounty.position.x) || 0;
  const countyPositionY =
    (hoveredCounty && hoveredCounty.position && hoveredCounty.position.y) || 0;
  const containerTop =
    (hoveredCounty && hoveredCounty.position && hoveredCounty.position.containerTop) || 0;
  const containerLeft =
    (hoveredCounty && hoveredCounty.position && hoveredCounty.position.containerLeft) || 0;
  const TOOLTIP_TOP_PADDING = 30;
  const TOOLTIP_WINDOW_PADDING = 10;
  const windowWidth = window.innerWidth - TOOLTIP_WINDOW_PADDING;
  // const windowHeight = window.innerHeight;

  let tooltipPositionTop = countyPositionY - containerTop - tooltipHeight - TOOLTIP_TOP_PADDING;
  let tooltipPositionLeft = countyPositionX - containerLeft - tooltipWidth / 2;
  if (tooltipPositionLeft + tooltipWidth > windowWidth)
    tooltipPositionLeft = windowWidth - tooltipWidth;
  if (tooltipPositionLeft < 0) tooltipPositionLeft = TOOLTIP_WINDOW_PADDING;

  useLayoutEffect(() => {
    setTooltipWidth(tooltipRef.current.clientWidth);
    setTooltipHeight(tooltipRef.current.clientHeight);
  }, [hoveredCounty, setTooltipWidth, setTooltipHeight]);

  const tooltipStyle = {
    display: hoveredCounty ? 'block' : 'none',
    top: tooltipPositionTop,
    left: tooltipPositionLeft,
  };
  return (
    <div
      ref={tooltipRef}
      className="map-tooltip"
      style={tooltipStyle}
      onMouseMove={e => e.stopPropagation()}
    >
      {hoveredCounty && (
        <Descriptions title={hoveredCounty.name} column={2} size="small">
          <Descriptions.Item label="Coverage" span={2}>
            {hoveredCounty.coverageStr || hoveredCounty.CoverageType}
          </Descriptions.Item>
          <Descriptions.Item label="Zip Count">
            {parseInt(hoveredCounty.zipCount) || '0'}
          </Descriptions.Item>
          <Descriptions.Item label="CC Zip Count">
            {parseInt(hoveredCounty.CCZip_Count) || '0'}
          </Descriptions.Item>
          <Descriptions.Item label="Amedisys Zip Count">
            {parseInt(hoveredCounty.AmedisysZip_Count) || '0'}
          </Descriptions.Item>
          <Descriptions.Item label="CC Agency Count">
            {parseInt(hoveredCounty.agency_Count) || '0'}
          </Descriptions.Item>
          <Descriptions.Item label="CC Patient Count">
            {parseInt(hoveredCounty.patient_count) || '0'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
});

const CareMap = React.memo(function CareMap(props) {
  // console.log('render CareMap:props', props);
  const { parsedData, filterValue } = props;
  if (!parsedData) return <Spin style={{ marginTop: 50 }} size="large" />;
  const getLocationClassName = (location, index) => {
    const locationData = (parsedData && parsedData[location.id]) || null;
    // if (!locationData) {
    //   console.log('non-matching county: ', location);
    // }
    let statusKey = (locationData && locationData.statusKey) || 'default';
    if (filterValue.length > 0) {
      // statusKey = filterValue.some(f => locationData.list && locationData.list.includes(f))
      statusKey = filterValue.every(
        f => locationData && locationData.list && locationData.list.includes(f),
      )
        ? statusKey
        : 'default';
    }
    let className = `svg-map__location caremap_location_${statusKey}`;
    return className;
  };

  return <SVGMap map={usaCounties} locationClassName={getLocationClassName} />;
});

function CareMapPage(props) {
  // console.log('CareMapPage rendered: props', props);
  const [parsedData, setParsedData] = useState(null);
  const [filterValue, setFilterValue] = useState([]);
  const tooltipEnabled = params.get('tooltip') === '1';

  useEffect(() => {
    Papa.parse(caremapdata, {
      delimiter: ',',
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: data => {
        // console.log('data', data);
        const arrayObj = {};
        data.data.forEach(item => {
          const itemKey = `${item.county
            .toLowerCase()
            .replace(/\s+/g, '-')}-${item.state.toLowerCase()}`;
          const statusKey = item.Amedisys + item.ClearCare + item.LHC;
          const list = [];
          if (item.Amedisys === '1') list.push('amd');
          if (item.ClearCare === '1') list.push('cc');
          if (item.LHC === '1') list.push('lhc');
          arrayObj[itemKey] = { ...item, statusKey, list };
        });
        setParsedData(arrayObj);
      },
    });
  }, []);

  const updateFilter = value => {
    if (filterValue.includes(value)) {
      setFilterValue(filterValue.filter(v => v !== value));
    } else {
      setFilterValue(filterValue.concat([value]));
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Care Coverage Map</h1>
        <div>
          <Button.Group>
            <Button
              type={filterValue.length === 0 ? 'primary' : 'default'}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                setFilterValue([]);
              }}
            >
              No Filter
            </Button>
            <Button
              type={filterValue.includes('cc') ? 'primary' : 'default'}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                updateFilter('cc');
              }}
            >
              ClearCare
            </Button>
            <Button
              type={filterValue.includes('amd') ? 'primary' : 'default'}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                updateFilter('amd');
              }}
            >
              Amedisys
            </Button>
            <Button
              type={filterValue.includes('lhc') ? 'primary' : 'default'}
              onMouseDown={e => e.preventDefault()}
              onClick={e => {
                e.preventDefault();
                updateFilter('lhc');
              }}
            >
              LHC
            </Button>
          </Button.Group>
        </div>
        <div className="legend" style={{ marginTop: 10 }}>
          <>
            <span className="caremap_location_000"></span>No Coverage
            <span className="caremap_location_010"></span>ClearCare Only
            <span className="caremap_location_100"></span>Amedisys Only
            <span className="caremap_location_110"></span>ClearCare + Amedisys
            <br />
            <span className="caremap_location_001"></span>LHC Only
            <span className="caremap_location_011"></span>ClearCare + LHC
            <span className="caremap_location_111"></span>ClearCare + Amedisys + LHC
            <span className="caremap_location_101"></span>Amedisys + LHC
          </>
        </div>
      </header>
      <MapContainer
        parsedData={parsedData}
        filterValue={filterValue}
        tooltipEnabled={tooltipEnabled}
      />
    </div>
  );
}

export default CareMapPage;
